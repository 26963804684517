import { PanelName } from '../editor-app/core/manage-panels/consts/panel-names'

const TEMPLATE_PICKER_PANEL = {
  OPEN_PANEL: 910,
  SELECT_TEMPLATE: 911,
  ADD_FORM_START: 989,
  ADD_FORM_COMPLETE: 912,
}
const MANAGE_FIELDS_PANEL = {
  DELETE_FIELD: 914,
}

const EDIT_FIELD_PANEL = {
  OPEN_PANEL: 917,
  VALUE_UPDATED: 918,
  TOGGLE_REQUIRED_FIELD: 919,
  OPEN_CONNECT_FIELD_DROPDOWN: 920,
  SELECT_FIELD_TO_CONNECT: 921,
  ADD_RESTRICTED_KEYWORD: 870,
}

const CONNECT_FIELD_PANEL = {
  OPEN_PANEL: 945,
}

const RECAPCHA_HELP_PANEL = {
  OPEN_PANEL: 940,
}

const ADI_EDIT_FORM_PANEL = {
  PANEL_LOADED: 942,
  DRAG_FIELD_COMPLETE: 944,
}

const ADI_EDIT_FIELD_PANEL = {
  CHANGE_FIELD_TITLE: 871,
  ADD_NEW_CHOICE: 872,
  DELETE_CHOICE: 874,
  SELECT_EDIT_CHOICE: 873,
  EDIT_CHOICE_DONE: 875,
  TOGGLE_DEFAULT_CHOICE: 876,
  DRAG_CHOICE_COMPLETE: 879,
}

const FORM_SETTINGS_PANEL = {
  OPEN_PANEL: 922,
  VALUE_UPDATED: 923,
  SECONDS_TO_RESET_UPDATED: 925,
  SUCCESS_ACTION_TYPE_SELECTED: 926,
  SUCCESS_LINK_SELECTED: 927,
  CREATE_SUBMISSIONS_TABLE: 928,
  SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY: 929,
  VIEW_SUBMISSIONS_TABLE: 931,
  CLICK_UPLOAD_BUTTON: 938,
  NAVIGATE_TO_TAB: 953,
  NAVIGATE_TO_SETTINGS_TAB: 954,
  NAVIGATE_TO_CONTACT_LIST: 956,
  NAVIGATE_TO_ABOUT_WIX_FORMS: 957,
  NAVIGATE_TO_WIX_SUPPORT: 958,
  NAVIGATE_TO_SEND_YOUR_FEEDBACK: 959,
  NAVIGATE_TO_REVIEW: 960,
  OPEN_AUTOMATIONS_RECIPES: 961,
  OPEN_EXTERNAL_APP_LINK: 955,
  RESTORE_MISSING_FIELD: 966,
  SELECT_LINK_ACTION: 965,
  PAYMENT_TAB_CLICK_GET_STARTED: 968,
  PAYMENT_TAB_CLICK_LEARN_MORE: 969,
  PAYMENT_TAB_CHANGE_CURRENCY: 973,
  CLICK_PAYMENT_OPTION_ACTION_IF_CONNECTED: 983,
  PAYMENT_TAB_CLICK_UPDATE_ITEM: 972,
  RESTORE_CRUCIAL_ELEMENTS: 894,
  CONTACT_LIST_CONTACT_LABELS_CLICK: 992,
  CONTACT_LIST_CONTACT_LABELS_UPDATE_VALUE: 993,
  CONTACT_LIST_CHOOSE_SAVED_FIELDS_CLICK: 994,
  GENERAL_ERROR_DISPLAYED: 1015,
  USER_CLICKED_TRY_AGAIN: 1016,
}

const CONTACT_SYNC_PANEL = {
  CONTACT_LIST_CHOOSE_SAVED_FIELDS_UPDATE_VALUE: 995,
  CONTACT_LIST_CHOOSE_SAVED_FIELDS_SAVE: 996,
  CONTACT_LIST_CHOOSE_SAVED_FIELDS_CANCEL: 998,
  CONTACT_LIST_CHOOSE_SAVED_FIELDS_LEARN_MORE: 999,
  CONTACT_LIST_CHOOSE_SAVED_FIELDS_HOVER_FORM_FIELDS: 1010,
  CONTACT_LIST_CHOOSE_SAVED_FIELDS_HOVER_SYNC_FIELDS: 1011,
  CONTACT_LIST_CHOOSE_SAVED_FIELDS_DROPDOWN_CLICK: 1012,
}

const DELETE_PAYMENT_ALERT_PANEL = {
  REMOVE_PAYMENT_POPUP_DISPLAYED: 981,
  REMOVE_PAYMENT_POPUP_ACTION: 982,
}

const PAYMENT_WIZARD_PANEL = {
  PAYMENT_WIZARD_DISPLAYED: 970,
  GET_STARTED_CLICK_DEMO_SITE: 971,
  ADD_ITEM_NAME_AND_PRICE: 972,
  CHANGE_CURRENCY: 973,
  CONNECT_PAYMENT_METHOD: 974,
  CHANGE_PAYMENT_METHOD: 976,
  PAYMENT_WIZARD_NEXT_AND_BACK: 984,
  HOVER_TOOLTIP_ICON: 985,
}

const MANAGE_STEPS_PANEL = {
  OPEN_PANEL: 883,
  DRAG_STEP: 882,
  EDIT_STEP: 885,
  ADD_STEP: 886,
  ADD_FIELD: 887,
  UPGRADE_ALERT: 889,
}

const CHANGE_BUTTON_LABEL_PANEL = {
  LABEL_CHANGED: 890,
  LABEL_FLUSHED: 891,
}

export const FORM_LAYOUT_PANEL = {
  OPEN_PANEL: 924,
  CHANGE_LAYOUT: 924,
  SPACE_BETWEEN_ROWS: 946,
  SPACE_BETWEEN_COLS: 947,
  TEXT_ALIGNMENT: 948,
  INPUT_TEXT_PADDING: 949,
  FIELD_TITLE_PADDING: 951,
  SPACE_BETWEEN_FIELD_TITLE_AND_TITLE: 950,
  FIELD_TITLE_TOGGLE: 952,
}

const ADD_FIELD_PANEL = {
  OPEN_PANEL: 915,
  SELECT_FIELD_TO_ADD: 916,
  ADD_FIELD_COMPLETE: 903,
}

const UPGRATE_ALERT_PANEL = {
  OPEN_PANEL: 934,
  ACTION_CLICK: 935,
}

const FORM_STYLE_PANEL = {
  CUSTOM_DESIGN_ACTION: 906,
}

const MANAGE_SUBSCRIBERS_PANEL = {
  OPEN_PANEL: 867,
  ACTION_CLICKED: 868,
}

const ALL_PANELS = {
  LOAD_FINISHED: 907,
}

const EDITOR = {
  DELETE_SUBMISSION_BUTTON: 933,
  USER_SAVE_TEMPLATE_WITH_FORM: 936,
  PASTE_APP_WIDGET: 912,
  DELETE_FORM: 908,
  FETCH_FAILED: 939,
  PAYMENT_NOTIFICATION_SETUP_CLICK: 988,
}

const PUBLISH_SITE_PANEL = {
  PANEL_OPENED: 963,
  PANEL_CLOSED: 964,
}

const SUBMIT_SETTINGS = {
  SETUP_PAYMENT: 896,
  UPDATE_SUBMIT_VALUE: 1013,
  OPEN_PANEL: 1014,
}

const PANELS = {
  [PanelName.ADD_FORM]: TEMPLATE_PICKER_PANEL,
  [PanelName.FORM_SETTINGS]: FORM_SETTINGS_PANEL,
  [PanelName.FORM_MANAGE_FIELDS]: MANAGE_FIELDS_PANEL,
  [PanelName.ADD_FIELD]: ADD_FIELD_PANEL,
  [PanelName.UPGRADE_ALERT]: UPGRATE_ALERT_PANEL,
  [PanelName.FIELD_SETTINGS]: EDIT_FIELD_PANEL,
  [PanelName.FORM_LAYOUT]: FORM_LAYOUT_PANEL,
  [PanelName.FORM_STYLE]: FORM_STYLE_PANEL,
  [PanelName.FORM_MANAGE_SUBSCRIBERS]: MANAGE_SUBSCRIBERS_PANEL,
  [PanelName.CONNECT_FIELD]: CONNECT_FIELD_PANEL,
  [PanelName.RECAPTCHA_HELP]: RECAPCHA_HELP_PANEL,
  [PanelName.PUBLISH_SITE_POP_UP]: PUBLISH_SITE_PANEL,
  [PanelName.DELETE_PAYMENT_ALERT]: DELETE_PAYMENT_ALERT_PANEL,
  [PanelName.PAYMENT_WIZARD]: PAYMENT_WIZARD_PANEL,
  [PanelName.ADI_EDIT_FORM]: ADI_EDIT_FORM_PANEL,
  [PanelName.ADI_EDIT_FIELD]: ADI_EDIT_FIELD_PANEL,
  [PanelName.MANAGE_STEPS]: MANAGE_STEPS_PANEL,
  [PanelName.CHANGE_BUTTON_LABEL]: CHANGE_BUTTON_LABEL_PANEL,
  [PanelName.CONTACT_SYNC_PANEL]: CONTACT_SYNC_PANEL,
  [PanelName.SUBMIT_SETTINGS]: SUBMIT_SETTINGS,
  allPanels: ALL_PANELS,
}

export const EVENTS = {
  PANELS,
  EDITOR,
}

export const ORIGINS = {
  GFPP: 'gfpp',
  MANAGE_FIELDS: 'manage fields',
  ADI_EDIT_FIELD: 'adi edit field',
}

export const TOGGLE_REQUIRED_ORIGINS = {
  CHECKED_BY_DEFAULT: 'checked by default',
  REQUIRED: 'required',
}

export const PANEL_NAMES = {
  formStylePanel: 'form design',
}

export const SETTINGS_PANEL_VALUE_UPDATED = {
  MESSAGE: 'success message',
  EMAIL: 'email',
}

export const FIELDS_PANEL_VALUE_UPDATED = {
  TITLE: 'title toggle',
}

export const FORM_TYPES = {
  FORM_BUILDER: 'FormBuilder',
  REGISTRATION_FORM: 'RegistrationForm',
}

export enum BI_PAYMENT_ITEM_ACTIONS {
  ADD = 'add',
  UPDATE = 'update',
}
